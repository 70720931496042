"use strict";

// Connecting vendors (plugins)
import "./_vendor";

// Functions
import { mobileCheck } from "./functions/mobile-check";
import { accordion } from "./functions/accordion";
import { burger } from "./functions/burger";

// Components
// import { formValidation } from "./components/formValidation";

window.addEventListener("DOMContentLoaded", () => {
  mobileCheck();
  burger();
  // formValidation();
  accordion(
    ".faq__list",
    "faq__item-title",
    ".faq__item",
    "faq__item--active",
  );
});
